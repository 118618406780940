import { styled } from '@linaria/react'

export const OrderedList = styled.ol`
  counter-reset: custom-counter;
  display: flex;
  flex-direction: column;
  align-items: baseline;

  & > * + * {
    margin-top: var(--spacing-16px);
  }
`
