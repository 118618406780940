import React, { FC } from 'react'

import { FormattedMessage } from 'react-intl'

import { VoidHandler } from 'common/types'
import { UnstyledButton } from 'components/designSystem/Button/UnstyledButton'
import { ListItem } from 'components/designSystem/ListItem/ListItem'
import { RoundButton } from 'components/designSystem/RoundButton/RoundButton'
import { MailSvg } from 'components/designSystem/svgr/MailSvg'
import { loginPath } from 'components/page/Onboarding/paths'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

import { LoginVariantText } from './LoginVariantText'
import {
  ButtonInner,
  ButtonLink,
  ListItemInner,
  LoginOptionTextWhite,
} from './styled'

export const LoginWithEmail: FC<{
  variant: 'in-list' | 'in-button'
  onClick?: VoidHandler
}> = ({ variant, onClick }) => {
  const { locale } = useShallowEqualSelector(
    ({ systemReducer: { locale } }) => ({ locale })
  )

  const iconElement = <MailSvg />
  const textElement = (
    <FormattedMessage
      id="app.email_and_password"
      defaultMessage="Email и пароль"
    />
  )

  switch (variant) {
    case 'in-list':
      return (
        <UnstyledButton data-name={BUTTON_DATA_NAME} onClick={onClick}>
          <ListItem size="M">
            <ListItemInner>
              <RoundButton size="S" interactive={false}>
                {iconElement}
              </RoundButton>
              <LoginVariantText>{textElement}</LoginVariantText>
            </ListItemInner>
          </ListItem>
        </UnstyledButton>
      )

    case 'in-button':
      return (
        <ButtonLink
          to={mergeAllUrls(locale, loginPath)}
          data-name={BUTTON_DATA_NAME}
        >
          <ButtonInner>
            {iconElement}
            <LoginOptionTextWhite>{textElement}</LoginOptionTextWhite>
          </ButtonInner>
        </ButtonLink>
      )

    default:
      return null
  }
}

const BUTTON_DATA_NAME = 'login-with-email-and-password-action'
