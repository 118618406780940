import React, { SVGProps } from 'react'
export const HeartSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={64}
    viewBox="0 0 64 64"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M21.928 51.904c2.46 1.406 4.892 2.982 7.634 3.7A13.16 13.16 0 0 0 32 56c.794-.054 1.611-.18 2.438-.396 2.741-.718 5.174-2.294 7.634-3.7 6.213-3.552 18.03-11.793 19.127-23.585C62.688 12.252 51.31 9.093 51.31 9.093 37.566 4.676 33.13 14.833 32 18.527c-1.132-3.694-5.566-13.852-19.311-9.434 0 0-11.377 3.159-9.888 19.226 1.096 11.792 12.914 20.033 19.127 23.585Z"
    />
  </svg>
)
