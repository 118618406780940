import React, { FC, ReactNode, useState } from 'react'

import { FormattedMessage } from 'react-intl'

import { FlyingBirdAnimation } from 'components/block/FlyingBirdAnimation/FlyingBirdAnimation'
import { MainLayout } from 'components/designSystem/layout/MainLayout/MainLayout'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

import { loginPath } from './paths'

export const RestorePasswordSuccess: FC<{
  title: ReactNode
  description: ReactNode
}> = ({ title, description }) => {
  const { locale } = useShallowEqualSelector(
    ({ systemReducer: { locale } }) => ({ locale })
  )

  const [loading, setLoading] = useState(false)

  return (
    <MainLayout
      noBorder
      icon={<FlyingBirdAnimation />}
      title={title}
      description={description}
      primaryButtonText={
        <FormattedMessage id="form.button.ok" defaultMessage="Понятно" />
      }
      onPrimaryButtonClick={() => {
        setLoading(true)
        window.location.replace(mergeAllUrls(locale, loginPath))
      }}
      primaryButtonDataName="restore-password-success-ok-action"
      primaryButtonLoading={loading}
      backButtonVisible={false}
    />
  )
}
