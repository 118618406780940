import React, { FC, useCallback, useEffect, useRef, useState } from 'react'

import { styled } from '@linaria/react'

import { SlideSvg } from 'components/designSystem/svgr/SlideSvg'
import { usePrevious } from 'hooks/usePrevious'

export const VerticalWaveSvgNative: FC<{
  step: number
  backgrounds: string[]
}> = ({ step, backgrounds }) => {
  const [node, setNode] = useState<SVGSVGElement | null>(null)
  const prevStep = usePrevious(step)
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const [wrapper, setWrapper] = useState<HTMLDivElement | null>(null)
  const stepRef = useRef<number>(step)
  const forwardRef = useRef<boolean>(true)
  const backgroundsRef = useRef<string[] | null>(null)
  const animationsRef = useRef<NodeListOf<Element> | null>(null)
  const isAnimatingRef = useRef(false)

  // Сохраним backgrounds в ref для оптимизации
  backgroundsRef.current = backgrounds

  useEffect(() => {
    if (!wrapper) return
    wrapper.style.background = backgroundsRef.current![stepRef.current]
  }, [wrapper])

  // Кладем значения в ref, чтобы можно было использовать ниже без проблем с reference equality
  useEffect(() => {
    stepRef.current = step
    forwardRef.current = step >= prevStep
  }, [prevStep, step])

  // При монтировании svg сохраним ссылки на элементы внутри svg для управления анимацией
  useEffect(() => {
    if (!node) return
    node.style.opacity = '0' // Скроем svg до запуска анимации из-за артефакта в самой svg
    animationsRef.current = node.querySelectorAll(
      'animate, animateMotion, animateTransform'
    )
  }, [node])

  const startAnimation = useCallback(() => {
    if (!node || !animationsRef.current) return
    node.style.opacity = '1' // Покажем svg

    node.querySelectorAll('path').forEach((path) => {
      // Установим цвет волны как у следующего экрана
      path.style.fill = backgroundsRef.current![stepRef.current]
    })
    // Повернем на 180 градусов, если шагаем назад
    node.style.transform = `rotate(${forwardRef.current ? 0 : 180}deg)`

    animationsRef.current.forEach((animation) =>
      (animation as any).beginElement()
    )
    isAnimatingRef.current = true
  }, [node])

  useEffect(() => {
    if (!node || !animationsRef.current) return
    // Все анимации длятся одинаковое кол-во времени, поэтому можно взять первую
    animationsRef.current[0].addEventListener('endEvent', () => {
      isAnimatingRef.current = false
      node.style.opacity = '0'
      const color = backgroundsRef.current![stepRef.current]
      wrapperRef.current!.style.background = color
    })
  }, [node])

  useEffect(() => {
    if (step !== prevStep) {
      startAnimation()
    }
  }, [step, prevStep, startAnimation])

  return (
    <BackgroundsWrapper
      ref={(node) => {
        wrapperRef.current = node
        setWrapper(node)
      }}
    >
      <SlideSvg _ref={setNode} />
    </BackgroundsWrapper>
  )
}

const BackgroundsWrapper = styled.div`
  position: absolute;
  inset: 0;
  overflow: hidden;
  z-index: -1;
  background: var(--pearl-soft);
`
