import React, { FC } from 'react'

import { styled } from '@linaria/react'
import { FormattedMessage } from 'react-intl'

import { VoidHandler } from 'common/types'
import {
  pathnameFormName,
  setCookieBannerPopUpRulesPath,
} from 'common-constants/formAction'
import { RouterLink } from 'components/designSystem/Link/RouterLink'
import { buttonResetCss } from 'components/designSystem/styles/buttonResetCss'
import { CloseSvg } from 'components/designSystem/svgr/CloseSvg'
import { Typography } from 'components/designSystem/Typography/Typography'

import { COOKIE_BANNER_CLOSE_BUTTON_DATA_NAME } from './BannerPopUpRulesCookie.constants'

export const BannerPopUpRulesCookieView: FC<{
  pathname: string
  confidentialityLink: string
  onCloseButtonClick: VoidHandler
}> = ({ pathname, confidentialityLink, onCloseButtonClick }) => {
  return (
    <form
      id="closeCookieBanner"
      action={setCookieBannerPopUpRulesPath}
      method="POST"
    >
      <Wrapper>
        <WrapperInner>
          <input type="hidden" name={pathnameFormName} value={pathname} />
          <Text fontSize={11}>
            <FormattedMessage
              id="cookie.notice.next"
              defaultMessage="Наш сайт использует файлы cookie. Продолжая использовать сайт, ты даешь на это согласие в соответствии с <link>Политикой в отношении обработки персональных данных</link>."
              values={{
                link: (chunks) => (
                  <RouterLink
                    to={confidentialityLink}
                    data-name="cookie-message-url-action"
                  >
                    {chunks}
                  </RouterLink>
                ),
              }}
            />
          </Text>
          <CloseButton
            type="submit"
            onClick={onCloseButtonClick}
            data-name={COOKIE_BANNER_CLOSE_BUTTON_DATA_NAME}
          >
            <CloseSvg width="20" height="20" />
            <StyledVisuallyHiddenText>
              <FormattedMessage id="app.link.close" defaultMessage="Закрыть" />
            </StyledVisuallyHiddenText>
          </CloseButton>
        </WrapperInner>
      </Wrapper>
    </form>
  )
}

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: var(--spacing-12px) var(--spacing-16px);
  z-index: 7;
  border-top: var(--spacing-1px) solid var(--border-muted);
  background: var(--background-page);
`
const WrapperInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 664px;
`
const Text = styled(Typography)`
  color: var(--foreground-soft);
`
const CloseButton = styled.button`
  ${buttonResetCss};

  html[dir='ltr'] & {
    margin-left: var(--spacing-12px);
  }

  html[dir='rtl'] & {
    margin-right: var(--spacing-12px);
  }
`
const StyledVisuallyHiddenText = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
`
