import React, { FC, useContext } from 'react'

import { styled } from '@linaria/react'
import { animated, useTransition } from '@react-spring/web'
import { useRouteMatch } from 'react-router'

import { goBackAction, goBackToAction } from 'actions/route/routeAction'
import { breakpoints } from 'components/designSystem/shared/breakpoints'
import { ArrowBackSvg } from 'components/designSystem/svgr/ArrowBackSvg'
import { indexPath } from 'components/paths'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

import { OnboardingContext } from './Onboarding.context'
import {
  enterBirthdayPath,
  enterNamePath,
  finalStepPath,
  restorePasswordEmailSuccessPath,
  restorePasswordPhoneSuccessPath,
  selectDatingPurposePath,
  selectGenderPath,
} from './paths'
import { ProgressBar } from './ProgressBar'

export const NavigationBar: FC<{
  step: number
}> = ({ step }) => {
  const { locale, animationsDisabled } = useShallowEqualSelector(
    ({ systemReducer: { locale, animationsDisabled } }) => ({
      locale,
      animationsDisabled,
    })
  )

  const emailSuccessRoute = useRouteMatch(
    mergeAllUrls(locale, restorePasswordEmailSuccessPath)
  )
  const phoneSuccessRoute = useRouteMatch(
    mergeAllUrls(locale, restorePasswordPhoneSuccessPath)
  )

  const visible = step > 0 && !emailSuccessRoute && !phoneSuccessRoute

  const dispatch = useAppDispatch()
  const { loginFlow } = useContext(OnboardingContext)

  const transitions = useTransition(visible, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 200 },
    immediate: Boolean(animationsDisabled),
  })

  const handleArrowClick = () => {
    if (loginFlow) {
      dispatch(goBackAction())
    } else {
      dispatch(goBackToAction(mergeAllUrls(locale, STEPS_PATHS_MAP[step - 1])))
    }
  }

  const progressStep = step - 1
  const progress = progressStep >= 0 ? progressStep / PROGRESS_STEPS_COUNT : 0

  return transitions(
    (springs, show) =>
      show && (
        <Navigation style={springs}>
          <NavigationBarWrapper>
            <NavigationBarInner>
              <NavigationBarArrowBack
                onClick={handleArrowClick}
                data-name="go-back-action"
              >
                <ArrowBackSvg />
              </NavigationBarArrowBack>
              {!loginFlow && <ProgressBar progress={progress} />}
            </NavigationBarInner>
          </NavigationBarWrapper>
        </Navigation>
      )
  )
}

const PROGRESS_STEPS_COUNT = 4

const STEPS_PATHS_MAP = {
  0: indexPath,
  1: selectGenderPath,
  2: enterNamePath,
  3: enterBirthdayPath,
  4: selectDatingPurposePath,
  5: finalStepPath,
}

const Navigation = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0;

  @media (min-width: ${breakpoints.mobile}px) {
    top: 25px;

    html[dir='ltr'] & {
      left: 25px;
    }

    html[dir='rtl'] & {
      right: 25px;
    }
  }
`
const NavigationBarWrapper = styled.div`
  html[dir='ltr'] & {
    padding-right: 16px;
  }

  html[dir='rtl'] & {
    padding-left: 16px;
  }

  @media (min-width: ${breakpoints.mobile}px) {
    html[dir='ltr'] & {
      padding-right: 80px;
    }

    html[dir='rtl'] & {
      padding-left: 80px;
    }
  }
`
const NavigationBarInner = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`
const NavigationBarArrowBack = styled.div`
  padding: var(--spacing-12px) var(--spacing-16px);
  cursor: pointer;
`
