import React, { FC } from 'react'

import { FormattedMessage } from 'react-intl'

import { Api6Errors } from 'api/Api6Errors'
import { ErrorFieldName } from 'components/page/DatingProfile/DatingProfile.constants'

export const ErrorName: FC<{ error: string }> = ({ error }) => {
  if (error === Api6Errors.profileRejected) {
    return (
      <FormattedMessage
        id="app.error.profile_rejected"
        defaultMessage="Профиль отклонен"
      />
    )
  }

  switch (error) {
    case ErrorFieldName.nameHasForbiddenCharacters:
      return (
        <FormattedMessage
          id="dating_profile.name.error.name_has_forbidden_characters"
          defaultMessage="Имя содержит запрещенные символы"
        />
      )
    case ErrorFieldName.nameHasMixedLocaleCharacters:
      return (
        <FormattedMessage
          id="dating_profile.name.error.name_has_mixed_locale_characters"
          defaultMessage="В имени используются одновременно символы кириллицы и латиницы"
        />
      )
    case ErrorFieldName.nameHasTooManyWords:
      return (
        <FormattedMessage
          id="dating_profile.name.error.name_has_too_many_words"
          defaultMessage="Превышен лимит слов в имени."
        />
      )

    case ErrorFieldName.nameHasTooShortWords:
      return (
        <FormattedMessage
          id="dating_profile.name.error.name_has_too_short_words"
          defaultMessage="Короткая часть имени"
        />
      )

    case ErrorFieldName.nameIsForbidden:
      return (
        <FormattedMessage
          id="app.incorrect_name"
          defaultMessage="Некорректное имя"
        />
      )

    case ErrorFieldName.nameIsTooLong:
      return (
        <FormattedMessage
          id="dating_profile.name.error.name_is_too_long"
          defaultMessage="Имя слишком длинное"
        />
      )

    case ErrorFieldName.nameIsTooShort:
      return (
        <FormattedMessage
          id="dating_profile.name.error.name_is_too_short"
          defaultMessage="Имя слишком короткое"
        />
      )

    default:
      return null
  }
}
