import React, {
  ChangeEventHandler,
  FC,
  KeyboardEventHandler,
  useContext,
} from 'react'

import { styled } from '@linaria/react'
import { FormattedMessage } from 'react-intl'

import { stepRegisterWithEmailAction } from 'actions/form/stepRegisterWithEmailAction'
import { updateRegistrationFieldAction } from 'actions/form/stepRegistrationAction'
import { VendorSocialList } from 'common-constants/vendorSocialList'
import { LoginVariants } from 'components/block/LoginVariants/LoginVariants'
import { OpenVendorAppBottomSheet } from 'components/block/OpenVendorAppBottomSheet/OpenVendorAppBottomSheet'
import { useBottomSheet } from 'components/designSystem/BottomSheet/BottomSheet'
import { PrimaryButton } from 'components/designSystem/Button/PrimaryButton'
import { Input } from 'components/designSystem/Input/Input'
import { OnboardingLayout } from 'components/designSystem/layout/OnboardingLayout/OnboardingLayout'
import { Typography } from 'components/designSystem/Typography/Typography'
import { UniNoticeId } from 'components/uninotice/uninotice.types'
import { isValidEmail } from 'functions/isValidEmail'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { StepRegistrationFieldList } from 'reducers/registration/stepRegistrationReducer'

import { LoginVariantsBottomSheet } from './LoginVariantsBottomSheet'
import { OnboardingContext } from './Onboarding.context'
import { finalStepPath } from './paths'
import { StepCommonProps } from './types'
import { loginVariantsPath } from '../Boarding/boarding.paths'
import { NOTIFICATION_REQUIRED_VENDORS } from '../Boarding/SocialNetworks/useVendorAppInstalledNotification'

export const EnterEmailStep: FC<StepCommonProps> = ({ active }) => {
  const dispatch = useAppDispatch()
  const { bottomSheetPortal } = useContext(OnboardingContext)

  const { email, errorText, authorizing, locale } = useShallowEqualSelector(
    ({
      stepRegistration: {
        form: { email },
        formErrors,
      },
      authorizationReducer: { authorizing },
      systemReducer: { locale },
    }) => ({ email, errorText: formErrors?.email, authorizing, locale })
  )

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    dispatch(
      updateRegistrationFieldAction(
        StepRegistrationFieldList.email,
        event.target.value
      )
    )
  }

  const handleSubmit = () => {
    dispatch(stepRegisterWithEmailAction())
  }

  const handleLinkEmailButtonClick = () => {
    handleSubmit()
  }

  const emailValid = isValidEmail(email)

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter' && emailValid) {
      handleSubmit()
    }
  }

  const { ref: openVendorAppBottomSheetRef, open: openVendorAppBottomSheet } =
    useBottomSheet()

  const handleVendorClick = (vendorName: string) => {
    if (
      NOTIFICATION_REQUIRED_VENDORS.includes(vendorName as VendorSocialList)
    ) {
      openVendorAppBottomSheet(vendorName)
    }
  }

  return (
    <OnboardingLayout
      title={
        <FormattedMessage
          id="app.select.login.option"
          defaultMessage="Мы у цели!{br}Выбери способ входа"
          values={{ br: <br /> }}
        />
      }
      subtitle={
        <FormattedMessage
          id="app.we.will.email.you.password"
          defaultMessage="Мы отправим пароль для входа."
        />
      }
    >
      <Input
        name="email"
        type="email"
        placeholder="Email"
        value={email}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        required
        disabled={!active}
        errorText={errorText}
      />
      <ButtonWrapper>
        <LinkEmailButton
          onClick={handleLinkEmailButtonClick}
          loading={authorizing}
          disabled={!emailValid}
          data-name="link-email-action"
        >
          <FormattedMessage
            id="app.link.email"
            defaultMessage="Привязать почту"
          />
        </LinkEmailButton>
      </ButtonWrapper>

      <LoginOptionsBlock>
        <Typography fontSize={14}>
          <FormattedMessage
            id="app.select_another_method"
            defaultMessage="Выбрать другой способ"
          />
        </Typography>

        <LoginOptionWrapper>
          <LoginVariants inlineVariant />
        </LoginOptionWrapper>
      </LoginOptionsBlock>

      <LoginVariantsBottomSheet
        showOnPath={mergeAllUrls(locale, finalStepPath, loginVariantsPath)}
        desktopPortal={bottomSheetPortal}
        onCloseReplaceToPath={mergeAllUrls(locale, finalStepPath)}
        onVendorClick={handleVendorClick}
      />

      <OpenVendorAppBottomSheet
        noticeId={UniNoticeId.externalMessengerOpenForAuth}
        bottomSheetRef={openVendorAppBottomSheetRef}
        desktopPortal={bottomSheetPortal}
      />
    </OnboardingLayout>
  )
}

const LoginOptionWrapper = styled.div`
  margin-top: var(--spacing-16px);
`
const ButtonWrapper = styled.div`
  margin-top: 40px;
`
const LinkEmailButton = styled(PrimaryButton)`
  width: 100%;
`
const LoginOptionsBlock = styled.div`
  margin-top: 24px;
  text-align: center;
`
