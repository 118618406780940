/**
 * This file is generated automatically
 * DO NOT EDIT IT MANUALLY
 */

export const colors = {
  foreground: {
    default: '#2e2a29',
    soft: '#615857',
    muted: '#99908e',
    disabled: 'rgba(98, 127, 153, 0.4)',
  },
  rose: {
    default: '#fbb9df',
    soft: '#fdddf0',
    hard: '#fc6abd',
    'on-rose': '#2e2a29',
    hover: '#ecaed2',
    press: '#dda3c4',
    'opacity-default': 'rgba(251, 185, 223, 0.48)',
    'opacity-hover': 'rgba(251, 185, 223, 0.64)',
    'opacity-press': 'rgba(251, 185, 223, 0.8)',
    'opacity-on-rose': '#2e2a29',
    'opacity-soft': 'rgba(251, 185, 223, 0.24)',
    'opacity-hard': 'rgba(252, 106, 189, 0.32)',
  },
  pearl: {
    default: '#ffded0',
    soft: '#ffefe8',
    hard: '#ffbb9d',
    'on-pearl': '#2e2a29',
    hover: '#f0d1c4',
    press: '#e0c3b7',
    'opacity-default': 'rgba(255, 222, 208, 0.48)',
    'opacity-hover': 'rgba(255, 222, 208, 0.64)',
    'opacity-press': 'rgba(255, 222, 208, 0.8)',
    'opacity-on-pearl': '#2e2a29',
    'opacity-soft': 'rgba(255, 222, 208, 0.24)',
    'opacity-hard': 'rgba(255, 187, 157, 0.32)',
  },
  purpur: {
    default: '#cebbff',
    soft: '#e7deff',
    hard: '#9066ff',
    hover: '#c2b0f0',
    press: '#b5a5e0',
    'on-purpur': '#2e2a29',
    'opacity-default': 'rgba(206, 187, 255, 0.48)',
    'opacity-hover': 'rgba(206, 187, 255, 0.64)',
    'opacity-press': 'rgba(206, 187, 255, 0.8)',
    'opacity-on-purpur': '#2e2a29',
    'opacity-soft': 'rgba(206, 187, 255, 0.24)',
    'opacity-hard': 'rgba(144, 102, 255, 0.32)',
  },
  splash: {
    default: '#b3dbff',
    soft: '#dbeeff',
    hard: '#5aadf7',
    hover: '#a8cef0',
    press: '#9ec1e0',
    'on-blue': '#2e2a29',
    'opacity-default': 'rgba(179, 219, 255, 0.48)',
    'opacity-hover': 'rgba(179, 219, 255, 0.64)',
    'opacity-press': 'rgba(179, 219, 255, 0.8)',
    'opacity-on-splash': '#2e2a29',
    'opacity-soft': 'rgba(179, 219, 255, 0.24)',
    'opacity-hard': 'rgba(90, 173, 247, 0.32)',
  },
  grass: {
    default: '#a9e788',
    soft: '#d6f3c6',
    hard: '#6cd634',
    hover: '#9fd980',
    press: '#95cb78',
    'on-grass': '#2e2a29',
    'opacity-default': 'rgba(169, 231, 136, 0.48)',
    'opacity-hover': 'rgba(169, 231, 136, 0.64)',
    'opacity-press': 'rgba(169, 231, 136, 0.8)',
    'opacity-on-grass': '#2e2a29',
    'opacity-soft': 'rgba(169, 231, 136, 0.24)',
    'opacity-hard': 'rgba(108, 214, 52, 0.32)',
  },
  sunshine: {
    default: '#ffe179',
    soft: '#fff1bf',
    hard: '#ffcc17',
    hover: '#f0d372',
    press: '#e0c66a',
    'on-sunshine': '#2e2a29',
    'opacity-default': 'rgba(255, 225, 121, 0.48)',
    'opacity-hover': 'rgba(255, 225, 121, 0.64)',
    'opacity-press': 'rgba(255, 225, 121, 0.8)',
    'opacity-on-sunshine': '#2e2a29',
    'opacity-soft': 'rgba(255, 225, 121, 0.24)',
    'opacity-hard': 'rgba(255, 204, 23, 0.32)',
  },
  mamba: {
    default: '#ff8640',
    soft: '#ffc5a3',
    hover: '#f07e3c',
    press: '#e07638',
    'on-mamba': '#f2f5f7',
    'opacity-default': 'rgba(255, 134, 64, 0.48)',
    'opacity-hover': 'rgba(255, 134, 64, 0.64)',
    'opacity-press': 'rgba(255, 134, 64, 0.8)',
    'opacity-on-mamba': '#2e2a29',
    'opacity-soft': 'rgba(255, 134, 64, 0.24)',
  },
  warm: {
    default: '#7f7472',
    soft: '#c2bcbb',
    hard: '#2e2a29',
    hover: '#776d6b',
    press: '#706664',
    'on-warm': '#f2f5f7',
    'opacity-default': 'rgba(127, 116, 114, 0.48)',
    'opacity-hover': 'rgba(127, 116, 114, 0.64)',
    'opacity-press': 'rgba(127, 116, 114, 0.8)',
    'opacity-on-warm': '#2e2a29',
    active: '#4c4644',
    'opacity-hard': 'rgba(46, 42, 41, 0.64)',
    'opacity-hover-list-item-bg': 'rgba(127, 116, 114, 0.08)',
    'opacity-press-list-item-bg': 'rgba(127, 116, 114, 0.24)',
  },
  cold: {
    default: '#627f99',
    soft: '#b4c2ce',
    hard: '#232e37',
    hover: '#5c7790',
    press: '#567087',
    'on-cold': '#f5f4f4',
    'opacity-default': 'rgba(98, 127, 153, 0.48)',
    'opacity-hover': 'rgba(98, 127, 153, 0.64)',
    'opacity-press': 'rgba(98, 127, 153, 0.8)',
    'opacity-on-cold': '#232e37',
    active: '#3b4c5c',
    'opacity-hard': 'rgba(35, 46, 55, 0.64)',
  },
  background: {
    page: '#ffffff',
    'surface.1': '#ffffff',
    'surface.2': '#faf9f9',
    'surface.3': '#f5f4f4',
    'opacity-disabled': 'rgba(98, 127, 153, 0.24)',
    none: 'rgba(255, 255, 255, 0)',
    disabled: '#e6ebef',
    opacity: {
      default: 'rgba(255, 255, 255, 0.48)',
    },
    'opacity-scrim': 'rgba(46, 42, 41, 0.4)',
    'surface.4': '#ebe9e8',
  },
  success: {
    default: '#3dd657',
    soft: '#83e594',
    hover: '#39c952',
    press: '#36bc4d',
    'on-success': '#2e2a29',
    'opacity-default': 'rgba(61, 214, 87, 0.48)',
    'opacity-hover': 'rgba(61, 214, 87, 0.64)',
    'opacity-press': 'rgba(61, 214, 87, 0.8)',
    'opacity-on-success': '#2e2a29',
  },
  warning: {
    default: '#f29100',
    soft: '#f7b95c',
    hover: '#e38800',
    press: '#d58000',
    'on-success': '#2e2a29',
    'opacity-default': 'rgba(242, 145, 0, 0.48)',
    'opacity-hover': 'rgba(242, 145, 0, 0.64)',
    'opacity-press': 'rgba(242, 145, 0, 0.8)',
    'opacity-on-success': '#2e2a29',
  },
  error: {
    default: '#ff3f15',
    soft: '#ff8469',
    hover: '#f03b14',
    press: '#e03712',
    'on-success': '#111111',
    'opacity-default': 'rgba(255, 63, 21, 0.48)',
    'opacity-hover': 'rgba(255, 63, 21, 0.64)',
    'opacity-press': 'rgba(255, 63, 21, 0.8)',
    'opacity-on-success': '#2e2a29',
  },
  elevated: {
    'bg-elevated-0': '#ffffff',
    'bg-elevated-s': '#ffffff',
    'bg-elevated-m': '#ffffff',
    'bg-elevated-l': '#ffffff',
    'bg-elevated-xl': '#ffffff',
  },
  border: {
    default: 'rgba(127, 116, 114, 0.48)',
    hard: 'rgba(127, 116, 114, 0.64)',
    muted: 'rgba(127, 116, 114, 0.16)',
    hover: '#7f7472',
    press: '#706664',
    disabled: 'rgba(98, 127, 153, 0.24)',
    soft: 'rgba(127, 116, 114, 0.24)',
  },
  'accent-primary': {
    default: '#2e2a29',
    hover: '#5c504b',
    press: '#7b6b64',
    'on-accent-primary': '#f2f5f7',
    opacity: {
      default: 'rgba(46, 42, 41, 0.56)',
    },
  },
  info: {
    default: '#5180fb',
    soft: '#90aefd',
    hover: '#4c78ec',
    press: '#3e61bf',
    'on-info': '#111111',
    'opacity-default': 'rgba(81, 128, 251, 0.48)',
    'opacity-hover': 'rgba(81, 128, 251, 0.64)',
    'opacity-press': 'rgba(81, 128, 251, 0.8)',
    'opacity-on-info': '#2e2a29',
  },
  'accent-secondary': {
    default: '#ffffff',
    hover: '#e7deff',
    press: '#cebbff',
    'on-accent-secondary': '#2e2a29',
  },
  static: {
    soft: '#f5f4f4',
    'opacity-black': 'rgba(17, 17, 17, 0.64)',
    black: '#111111',
    accent: '#ff8640',
    white: '#ffffff',
    hover: '#ff9e66',
    'opacity-white': 'rgba(255, 255, 255, 0.64)',
    press: '#ffb285',
  },
  'colorful.background': {
    'pearl-bg': '#ffded0',
    'rose-bg': '#fbb9df',
    'purpur-bg': '#cebbff',
    'splash-bg': '#b3dbff',
    'grass-bg': '#a9e788',
  },
}
