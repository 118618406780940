import React, { FC } from 'react'

import { styled } from '@linaria/react'
import { useRouteMatch } from 'react-router'

import { VoidHandler } from 'common/types'
import { RouterLink } from 'components/designSystem/Link/RouterLink'
import { Loader } from 'components/designSystem/Loader/Loader'
import { buttonResetCss } from 'components/designSystem/styles/buttonResetCss'
import { ThreeDotsSvg } from 'components/designSystem/svgr/ThreeDotsSvg'
import { loginVariantsPath } from 'components/page/Boarding/boarding.paths'
import { useCloseCookiesBanner } from 'components/page/Onboarding/hooks/useCloseCookiesBanner'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

import { CreateProfileButton } from './CreateProfileButton'
import { LoginWithEmail } from './LoginWithEmail'
import { LoginWithVendor } from './LoginWithVendor'

export const LoginVariants: FC<{
  inlineVariant: boolean
  onLoginWithEmailClick?: VoidHandler
  onCreateProfileClick?: VoidHandler
  onVendorClick?: (vendorName: string) => void
}> = ({
  inlineVariant,
  onLoginWithEmailClick,
  onCreateProfileClick,
  onVendorClick,
}) => {
  const match = useRouteMatch()
  useCloseCookiesBanner(!inlineVariant)

  const { vendors, vendorsLoading } = useShallowEqualSelector(
    ({ authorizationReducer: { vendors, vendorsLoading } }) => ({
      vendors,
      vendorsLoading,
    })
  )

  if (inlineVariant) {
    return (
      <Inline>
        {vendors.slice(0, VENDORS_INLINE_COUNT).map((vendor) => (
          <LoginWithVendor
            key={vendor.name}
            vendor={vendor}
            variant="inline"
            onVendorClick={onVendorClick}
          />
        ))}
        <TreeDotsWrapper
          data-name="more-login-variants-action"
          to={mergeAllUrls(match.url, loginVariantsPath)}
          replace
        >
          <ThreeDotsSvg />
        </TreeDotsWrapper>
      </Inline>
    )
  }

  if (vendorsLoading) {
    return <Loader />
  }

  return (
    <List>
      {onLoginWithEmailClick && (
        <LoginWithEmail variant="in-list" onClick={onLoginWithEmailClick} />
      )}
      {vendors.map((vendor) => (
        <LoginWithVendor
          key={vendor.name}
          vendor={vendor}
          variant="in-list"
          onVendorClick={onVendorClick}
        />
      ))}
      {onCreateProfileClick && (
        <CreateProfileButton onClick={onCreateProfileClick} />
      )}
    </List>
  )
}

const VENDORS_INLINE_COUNT = 3

const List = styled.ul`
  display: flex;
  flex-direction: column;
  margin-left: -16px;
  margin-right: -16px;
  width: calc(100% + 32px);
`
const TreeDotsWrapper = styled(RouterLink)`
  ${buttonResetCss};
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    cursor: pointer;
  }
`
const Inline = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: 12px;
  gap: 12px;
`
