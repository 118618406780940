import React, { SVGProps } from 'react'

export const ManSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={65}
    viewBox="0 0 64 65"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.409 16.047c.931 12.03 8.431 17.792 13.835 19.886h-.013a2.77 2.77 0 0 0-2.78 2.76 2.77 2.77 0 0 0 2.78 2.761 2.77 2.77 0 0 0 2.779-2.76 2.767 2.767 0 0 0-2.267-2.715c.963-7.026 3.872-13.029 12.067-13.029 11.117 0 12.506 8.283 12.506 11.044 0 2.76 0 6.902 2.78 5.522 2.779-1.38 2.779 1.38 2.779 2.76 0 1.381-1.39 2.762-2.78 4.142-1.389 1.38-2.779 2.761-2.779 5.522v1.56c0 1.673 1.566 2.942 2.992 2.065.409-.252.803-.54 1.177-.864 2.78-2.3 8.615-8.835 9.727-16.566 1.303-9.064.162-10.84-8.012-23.558l-.01-.016-1.705-2.655C38.758-3.279 30.421-1.898 30.421 5.004c0 5.521-5.558 2.3-8.337 0C17.335 1.073 4.297 1.69 5.409 16.047Zm24.938 25.407a2.77 2.77 0 0 0 2.78-2.76 2.77 2.77 0 0 0-2.78-2.762 2.77 2.77 0 0 0-2.779 2.761 2.77 2.77 0 0 0 2.78 2.761Zm-4.168 6.504c.764-.441 1.611-.866 2.488-.962.616-.068 1.425.026 2.397.483.709.334 1.21.667 1.625.943.299.199.578.351.842.464 1.777.762 2.566.96.84 1.828a9.015 9.015 0 0 1-.846.374c1.849-.264 4.182-2.267 5.922-4.22 1.137-1.278 2.627-1.133 2.57.576-.105 3.107-1.137 7.668-3.332 10.576-4.169 5.522-6.948 6.903-15.285 5.522-8.338-1.38-6.948-6.902-5.559-9.663a4.99 4.99 0 0 1 2.07-2.164 13.196 13.196 0 0 1-2.293-1.385c-1.017-.778-.258-1.145.992-1.427.353-.08.694-.238 1.058-.48.416-.277.917-.61 1.626-.944.941-.443 1.73-.57 2.34-.54.907.045 1.757.564 2.545 1.019Zm-3.02 4.195c2.896 2.325 4.558 1.333 6.345.266l.308-.184c-.76-.053-1.56-.289-2.38-.77a8.544 8.544 0 0 1-1.253-.875 8.537 8.537 0 0 1-1.254.874 5.643 5.643 0 0 1-1.767.69Z"
      clipRule="evenodd"
    />
  </svg>
)
