import React, { SVGProps } from 'react'

export const WomanSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={65}
    viewBox="0 0 64 65"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M42.537 34.61c-.96-.59-1.26-1.77-1.26-2.567 0-9.6-6.52-12.89-9.78-13.334-11.312 0-15.579 5.171-17.189 10.683a4 4 0 1 1-.968 7.759c-.007.55-.007 1.072-.007 1.558 0 6.667 2.794 13.334 8.383 13.334 4.471 0 5.59 3.555 5.59 5.333v2.978c-.46.704-1.272 1.134-2.172.996C16 59.95 3.582 53.296 2.713 33.787 1.41 4.561 27.11 1.202 36.58 3.165c.268.056.542.112.82.168 9.386 1.919 23.932 4.892 23.932 34.628 0 6.71-3.366 18.809-18.202 22.584a2.196 2.196 0 0 1-2.05-.523c-.19-6.857.245-16.439 2.992-17.313 3.979-1.265 5.44-8.539 1.992-8.133-.38-.329-.945-.43-1.719-.183-.724.232-1.32.286-1.808.218Z"
    />
    <path fill="currentColor" d="M32 33.376a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" />
  </svg>
)
