/**
 * This file is generated automatically
 * DO NOT EDIT IT MANUALLY
 */

export const colors = {
  foreground: {
    default: '#f2f5f7',
    soft: '#cdd6de',
    muted: '#8199ad',
    disabled: 'rgba(98, 127, 153, 0.4)',
  },
  rose: {
    default: '#fdddf0',
    soft: '#fcc7e5',
    hard: '#f88ac9',
    'on-rose': '#2e2a29',
    hover: '#fbb9df',
    press: '#ecaed2',
    'opacity-default': 'rgba(251, 185, 223, 0.16)',
    'opacity-hover': 'rgba(251, 185, 223, 0.08)',
    'opacity-press': 'rgba(251, 185, 223, 0.06)',
    'opacity-on-rose': '#f2f5f7',
    'opacity-soft': 'rgba(251, 185, 223, 0.12)',
    'opacity-hard': 'rgba(248, 138, 201, 0.32)',
  },
  pearl: {
    default: '#ffefe8',
    soft: '#ffe5d9',
    hard: '#ffceb9',
    'on-pearl': '#2e2a29',
    hover: '#ffded0',
    press: '#f0d1c4',
    'opacity-default': 'rgba(255, 222, 208, 0.16)',
    'opacity-hover': 'rgba(255, 222, 208, 0.08)',
    'opacity-press': 'rgba(255, 222, 208, 0.06)',
    'opacity-on-pearl': '#f2f5f7',
    'opacity-soft': 'rgba(255, 222, 208, 0.12)',
    'opacity-hard': 'rgba(255, 206, 185, 0.32)',
  },
  purpur: {
    default: '#e7deff',
    soft: '#d8c9ff',
    hard: '#ac8dff',
    hover: '#cebbff',
    press: '#c2b0f0',
    'on-purpur': '#2e2a29',
    'opacity-default': 'rgba(206, 187, 255, 0.16)',
    'opacity-hover': 'rgba(206, 187, 255, 0.08)',
    'opacity-press': 'rgba(206, 187, 255, 0.06)',
    'opacity-on-purpur': '#f2f5f7',
    'opacity-soft': 'rgba(206, 187, 255, 0.12)',
    'opacity-hard': 'rgba(172, 141, 255, 0.32)',
  },
  splash: {
    default: '#dbeeff',
    soft: '#c2e2ff',
    hard: '#7fc3ff',
    hover: '#b3dbff',
    press: '#a8cef0',
    'on-blue': '#2e2a29',
    'opacity-default': 'rgba(179, 219, 255, 0.16)',
    'opacity-hover': 'rgba(179, 219, 255, 0.08)',
    'opacity-press': 'rgba(179, 219, 255, 0.06)',
    'opacity-on-splash': '#f2f5f7',
    'opacity-soft': 'rgba(179, 219, 255, 0.12)',
    'opacity-hard': 'rgba(127, 195, 255, 0.32)',
  },
  grass: {
    default: '#d6f3c6',
    soft: '#baeca0',
    hard: '#87e157',
    hover: '#a9e788',
    press: '#9fd980',
    'on-grass': '#2e2a29',
    'opacity-default': 'rgba(169, 231, 136, 0.16)',
    'opacity-hover': 'rgba(169, 231, 136, 0.08)',
    'opacity-press': 'rgba(169, 231, 136, 0.06)',
    'opacity-on-grass': '#f2f5f7',
    'opacity-soft': 'rgba(169, 231, 136, 0.12)',
    'opacity-hard': 'rgba(135, 225, 87, 0.32)',
  },
  sunshine: {
    default: '#fff1bf',
    soft: '#ffe794',
    hard: '#ffdc19',
    hover: '#ffe179',
    press: '#f0d372',
    'on-sunshine': '#2e2a29',
    'opacity-default': 'rgba(255, 225, 121, 0.16)',
    'opacity-hover': 'rgba(255, 225, 121, 0.08)',
    'opacity-press': 'rgba(255, 225, 121, 0.06)',
    'opacity-on-sunshine': '#f2f5f7',
    'opacity-soft': 'rgba(255, 225, 121, 0.12)',
    'opacity-hard': 'rgba(255, 220, 25, 0.32)',
  },
  mamba: {
    default: '#ff8640',
    soft: '#ff9e66',
    hover: '#f07e3c',
    press: '#e07638',
    'on-mamba': '#2e2a29',
    'opacity-default': 'rgba(255, 134, 64, 0.16)',
    'opacity-hover': 'rgba(255, 134, 64, 0.08)',
    'opacity-press': 'rgba(255, 134, 64, 0.06)',
    'opacity-on-mamba': '#f2f5f7',
    'opacity-soft': 'rgba(255, 134, 64, 0.12)',
  },
  warm: {
    default: '#c2bcbb',
    soft: '#99908e',
    hard: '#ebe9e8',
    hover: '#7f7472',
    press: '#776d6b',
    'on-warm': '#2e2a29',
    'opacity-default': 'rgba(127, 116, 114, 0.16)',
    'opacity-hover': 'rgba(127, 116, 114, 0.12)',
    'opacity-press': 'rgba(127, 116, 114, 0.08)',
    'opacity-on-warm': '#f2f5f7',
    active: '#d6d3d2',
    'opacity-hard': 'rgba(235, 233, 232, 0.2)',
    'opacity-hover-list-item-bg': 'rgba(127, 116, 114, 0.08)',
    'opacity-press-list-item-bg': 'rgba(127, 116, 114, 0.24)',
  },
  cold: {
    default: '#b4c2ce',
    soft: '#8199ad',
    hard: '#f5f4f4',
    hover: '#627f99',
    press: '#5c7790',
    'on-cold': '#232e37',
    'opacity-default': 'rgba(98, 127, 153, 0.16)',
    'opacity-hover': 'rgba(98, 127, 153, 0.12)',
    'opacity-press': 'rgba(98, 127, 153, 0.08)',
    'opacity-on-cold': '#f5f4f4',
    active: '#cdd6de',
    'opacity-hard': 'rgba(245, 244, 244, 0.2)',
  },
  background: {
    page: '#111111',
    'surface.1': '#111111',
    'surface.2': '#2e2a29',
    'surface.3': '#3d3837',
    'opacity-disabled': 'rgba(98, 127, 153, 0.24)',
    none: 'rgba(17, 17, 17, 0)',
    disabled: '#e6ebef',
    opacity: {
      default: 'rgba(17, 17, 17, 0.48)',
    },
    'opacity-scrim': 'rgba(35, 46, 55, 0.4)',
    'surface.4': '#4c4644',
  },
  success: {
    default: '#64de79',
    soft: '#83e594',
    hover: '#39c952',
    press: '#36bc4d',
    'on-success': '#2e2a29',
    'opacity-default': 'rgba(61, 214, 87, 0.16)',
    'opacity-hover': 'rgba(61, 214, 87, 0.12)',
    'opacity-press': 'rgba(61, 214, 87, 0.08)',
    'opacity-on-success': '#f2f5f7',
  },
  warning: {
    default: '#f5a733',
    soft: '#f7b95c',
    hover: '#e38800',
    press: '#d58000',
    'on-success': '#2e2a29',
    'opacity-default': 'rgba(242, 145, 0, 0.16)',
    'opacity-hover': 'rgba(242, 145, 0, 0.12)',
    'opacity-press': 'rgba(242, 145, 0, 0.08)',
    'opacity-on-success': '#f2f5f7',
  },
  error: {
    default: '#ff6644',
    soft: '#ff8469',
    hover: '#f03b14',
    press: '#e03712',
    'on-success': '#2e2a29',
    'opacity-default': 'rgba(255, 63, 21, 0.16)',
    'opacity-hover': 'rgba(255, 63, 21, 0.12)',
    'opacity-press': 'rgba(255, 63, 21, 0.08)',
    'opacity-on-success': '#f2f5f7',
  },
  elevated: {
    'bg-elevated-0': '#111111',
    'bg-elevated-s': '#2e2a29',
    'bg-elevated-m': '#3d3837',
    'bg-elevated-l': '#4c4644',
    'bg-elevated-xl': '#615857',
  },
  border: {
    default: 'rgba(98, 127, 153, 0.48)',
    hard: 'rgba(98, 127, 153, 0.64)',
    muted: 'rgba(98, 127, 153, 0.16)',
    hover: '#627f99',
    press: '#567087',
    disabled: 'rgba(98, 127, 153, 0.24)',
    soft: 'rgba(98, 127, 153, 0.24)',
  },
  'accent-primary': {
    default: '#ffefe8',
    hover: '#ffe5d9',
    press: '#f0d1c4',
    'on-accent-primary': '#2e2a29',
    opacity: {
      default: 'rgba(255, 239, 232, 0.56)',
    },
  },
  info: {
    default: '#7499fc',
    soft: '#90aefd',
    hover: '#4c78ec',
    press: '#3e61bf',
    'on-info': '#2e2a29',
    'opacity-default': 'rgba(81, 128, 251, 0.16)',
    'opacity-hover': 'rgba(81, 128, 251, 0.12)',
    'opacity-press': 'rgba(81, 128, 251, 0.08)',
    'opacity-on-info': '#f2f5f7',
  },
  'accent-secondary': {
    default: '#ffffff',
    hover: '#e7deff',
    press: '#cebbff',
    'on-accent-secondary': '#2e2a29',
  },
  static: {
    soft: '#f5f4f4',
    'opacity-black': 'rgba(17, 17, 17, 0.64)',
    black: '#111111',
    accent: '#ff8640',
    white: '#ffffff',
    hover: '#ff9e66',
    'opacity-white': 'rgba(255, 255, 255, 0.64)',
    press: '#ffb285',
  },
  'colorful.background': {
    'pearl-bg': '#332c2a',
    'rose-bg': '#32252d',
    'purpur-bg': '#292533',
    'splash-bg': '#242c33',
    'grass-bg': '#222e1b',
  },
}
