import { breakpoints } from 'components/designSystem/shared/breakpoints'
import { sizes } from 'components/presentational'

export const isShowLeftLayout = () => {
  if (process.env.browser) {
    return window.innerWidth > sizes.tabletLarge
  }
  return false
}

export const isShowLeftLayoutNext = () => {
  if (process.env.browser) {
    return window.innerWidth >= breakpoints.startDesktop
  }

  return false
}
