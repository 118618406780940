import { useState, useEffect } from 'react'

import { colors as darkColors } from './darkColors'
import { colors as lightColors } from './lightColors'

type ThemeType = 'light' | 'dark'

/**
 * Заготовка для переключения темы в проекте
 * В данный момент, всегда используется 'light' темы
 *
 * Можно использовать для получения текущих цветов
 */
export const useThemeNext = () => {
  const [theme, setTheme] = useState<ThemeType>(() => {
    // return (localStorage.getItem('theme') as ThemeType) || 'light'
    return 'light'
  })

  useEffect(() => {
    if (theme === 'dark') {
      document.body.classList.add('dark-theme')
    } else {
      document.body.classList.remove('dark-theme')
    }
  }, [theme])

  const toggleTheme = () => {
    setTheme((prev) => {
      const newTheme = prev === 'light' ? 'dark' : 'light'
      localStorage.setItem('theme', newTheme)
      return newTheme
    })
  }

  return {
    theme,
    colors: theme === 'light' ? lightColors : darkColors,
    toggleTheme,
  }
}
